/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Modal,
  Container,
  Alert,
  Table,
  Button,
} from "react-bootstrap";
// import InputFields from "../InputFields";
import { Form, Control } from "react-redux-form";
// import Footer from "../Footer";
// import Loading from "../Loading";
// import NavIcon from "./navIcons";
// import closeIcon from "../../global/img/close.png";
// import { request } from "../utils/index";
import "./style.css";
import InputFields from "../InputFields";

function MatchupModal(props) {
  const { matchupInfo, dispatch, userActions, onHide } = props;
  const {
    id,
    away_team,
    away_team_city,
    away_team_name,
    home_team,
    home_team_city,
    home_team_name,
    over_under_prediction_outcome,
    over_under_prediction_outcome_override,
    favored_team_by_predicted_spread,
    favored_team_by_predicted_spread_override,
  } = matchupInfo;

  let favoredTeamBySpread;
  if (
    favored_team_by_predicted_spread?.split(":")[0]?.toUpperCase() === "AWAY"
  ) {
    favoredTeamBySpread = `Away Team: ${away_team_city} ${away_team_name}`;
  } else {
    favoredTeamBySpread = `Home Team: ${home_team_city} ${home_team_name}`;
  }

  const submitAdminChanges = () => {
    const overUnderChanges =
      userActions.admin_actions.overrides.over_under_prediction_outcome;
    const spreadChanges =
      userActions.admin_actions.overrides.favored_team_by_predicted_spread;
    dispatch({
      type: "SUBMIT_ADMIN_CHANGES",
      payload: {
        id,
        over_under_prediction_outcome,
        over_under_prediction_outcome_override,
        favored_team_by_predicted_spread,
        favored_team_by_predicted_spread_override,
        overUnderChanges: overUnderChanges || null,
        spreadChanges: spreadChanges || null,
        resetOverrides:
          userActions.admin_actions.overrides?.reset_manual_overrides,
      },
    });
    onHide();
  };

  const overUnderOptions = [
    {
      label: "Over",
      value: "Over",
    },
    {
      label: "Under",
      value: "Under",
    },
    {
      label: "Unpredictable",
      value: "Unpredictable",
    },
  ];
  const preferredTeamBySpreadOptions = [
    {
      label: `Away Team (${away_team})`,
      value: `Away:${away_team}`,
    },
    {
      label: `Home Team (${home_team})`,
      value: `Home:${home_team}`,
    },
    {
      label: "Unpredictable",
      value: "Unpredictable",
    },
  ];

  return (
    <Modal
      {...props}
      id="adminMatchupModal"
      className="tertiaryFontBold"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <b>Modify Matchup Picks</b>
      </Modal.Header>
      <Modal.Body>
        <Form
          model="userActions.admin_actions.overrides"
          onSubmit={() => submitAdminChanges()}
        >
          <Row>
            <Col lg={12}>
              <p>
                {away_team_city} {away_team_name} vs {home_team_city}{" "}
                {home_team_name}
              </p>
            </Col>
            <Col lg={12}>
              <p>
                Current Over/Under Prediction:{" "}
                <span className="adminPickValues">
                  {over_under_prediction_outcome}
                </span>
                <br />
                Current Preferred Team by Spread Prediction:{" "}
                <span className="adminPickValues">{favoredTeamBySpread}</span>
              </p>
            </Col>
            <Col lg={6}>
              <div className="inputCol">
                <InputFields
                  model=".over_under_prediction_outcome"
                  inputType="select"
                  type="select"
                  // label="Update Over/Under"
                  placeholder="Update Over/Under"
                  defaultOption="Update Over/Under"
                  // value={over_under_prediction_outcome}
                  ignore={true}
                  options={overUnderOptions}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="inputCol">
                <InputFields
                  model=".favored_team_by_predicted_spread"
                  inputType="select"
                  type="select"
                  // label="Update Preferred Team by Spread"
                  placeholder="Update Preferred Team by Spread"
                  defaultOption="Update Preferred Team by Spread"
                  // value={favored_team_by_predicted_spread}
                  ignore={true}
                  options={preferredTeamBySpreadOptions}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="inputCol">
                <span>Reset all overrides?</span>
                <InputFields
                  model=".reset_manual_overrides"
                  inputType="radio"
                  type="radio"
                  label="Yes"
                  value="Yes"
                  ignore={true}
                />
                <InputFields
                  model=".reset_manual_overrides"
                  inputType="radio"
                  type="radio"
                  label="No"
                  value="No"
                  ignore={true}
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="inputCol">
                <Control.button
                  model="userActions.admin_actions.overrides"
                  className="generalButton"
                  type="submit"
                >
                  Submit Changes
                </Control.button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

function AdminDashboard(props) {
  const { dispatch, schedules, user, userActions } = props;
  const [matchupModalShow, setMatchupModalShow] = useState(false);
  const [game, setGame] = useState({});

  if (!user?.admin) {
    return (
      <Container>
        <div className="containerDiv">
          <Row>
            <Col lg={12}>
              <Alert
                key="danger"
                variant="danger"
                className="authorizationAlert tertiaryFontBold"
              >
                You are not authorized to access this page.
              </Alert>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }

  const games = schedules?.nfl?.games || [];

  const searchMatchups = () => {
    const { season, week } = userActions.admin_actions.search;
    dispatch({ type: "SEARCH_GAMES", payload: { season, week } });
  };

  // const searchFilter = (v) => {
  //   const searchValue = v?.target?.value?.toUpperCase();
  //   const filteredSearch = searchValue
  //     ? schedules?.nfl?.schedules?.filter(
  //         (n) =>
  //           n?.away_team_city?.toUpperCase()?.startsWith(searchValue) ||
  //           n?.away_team_name?.toUpperCase()?.startsWith(searchValue) ||
  //           n?.home_team_city?.toUpperCase()?.startsWith(searchValue) ||
  //           n?.home_team_name?.toUpperCase()?.startsWith(searchValue)
  //       )
  //     : schedules?.nfl?.schedules;
  //   return;
  // };

  const seasons = [{ label: 2023, value: 2023 }];
  const weeks = [...new Array(18)].map((element, i) => {
    return { label: i + 1, value: i + 1 };
  });

  return (
    <Container>
      <div className="containerDiv">
        <MatchupModal
          show={matchupModalShow}
          onHide={() => setMatchupModalShow(false)}
          dispatch={dispatch}
          userActions={userActions}
          matchupInfo={game}
        />
        <div className="adminHeaderDiv">
          <h1 className="secondaryFontBold">Admin Dashboard</h1>
          <h3 className="secondaryFontBold">
            Please choose a season and week below
          </h3>
        </div>
        <Form
          model="userActions.admin_actions.search"
          onSubmit={() => searchMatchups()}
        >
          <Row>
            <Col lg={6}>
              <div className="inputCol">
                <InputFields
                  model=".season"
                  inputType="select"
                  type="select"
                  placeholder="Choose Season"
                  defaultOption="Season"
                  options={seasons}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="inputCol">
                <InputFields
                  model=".week"
                  inputType="select"
                  type="select"
                  placeholder="Choose Season"
                  defaultOption="Week"
                  options={weeks}
                />
              </div>
            </Col>
            <Col lg={1}>
              <div className="inputCol">
                <Control.button
                  model="userActions.nfl_overrides"
                  className="generalButton"
                  type="submit"
                >
                  Search
                </Control.button>
              </div>
            </Col>
          </Row>
        </Form>
        {/* <Row>
          <Col lg={12}>
            <form className="searchInput">
              <input
                className="searchInput mainFontRegular"
                type="search"
                placeholder="Search by Team City or Name..."
                onChange={(v) => searchFilter(v)}
              />
            </form>
          </Col>
        </Row> */}
        {games?.length ? (
          <>
            <hr />
            <Row>
              <Col lg={12}>
                <Table striped bordered hover className="adminTable">
                  <thead>
                    <tr>
                      <th>Edit</th>
                      <th>Season</th>
                      <th>Week</th>
                      <th>Date</th>
                      <th>Matchup</th>
                      <th>Over/Under Prediction</th>
                      <th>Favored Team by Spread Prediction</th>
                      <th>Over/Under Override Used?</th>
                      <th>Spread Override Used?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {games?.map((g) => (
                      <tr>
                        <td>
                          <Button
                            className="modifyMatchupButton generalButton"
                            onClick={() => {
                              setMatchupModalShow(true);
                              setGame(g);
                            }}
                          >
                            Edit
                          </Button>
                        </td>
                        <td>
                          <p>{g.season}</p>
                        </td>
                        <td>
                          <p>{g.week}</p>
                        </td>
                        <td>
                          <p>
                            {" "}
                            {g.date_time?.date}
                            <br />
                            {g.date_time?.time}
                          </p>
                        </td>
                        <td>
                          <p>
                            {g.away_team_city} {g.away_team_name} vs{" "}
                            {g.home_team_city} {g.home_team_name}
                          </p>
                        </td>
                        <td>
                          <p className="adminPickValues">
                            {!g.disable_over_under_bet
                              ? g.over_under_prediction_outcome
                              : "Unpredictable"}
                          </p>
                        </td>
                        <td>
                          <p className="adminPickValues">
                            {!g.disable_spread_bet
                              ? g.favored_team_by_predicted_spread?.split(
                                  ":"
                                )[1]
                              : "Unpredictable"}
                          </p>
                        </td>
                        <td>
                          <p className="adminPickValues">
                            {g.over_under_prediction_outcome_override ||
                            g.disable_over_under_bet
                              ? "Yes"
                              : "No"}
                          </p>
                        </td>
                        <td>
                          <p className="adminPickValues">
                            {g.favored_team_by_predicted_spread_override ||
                            g.disable_spread_bet
                              ? "Yes"
                              : "No"}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        ) : null}
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  schedules: state.schedules,
});

export default connect(mapStateToProps)(AdminDashboard);
