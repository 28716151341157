import { put, takeLeading, all, call, select } from "redux-saga/effects";
import * as selectors from "./selectors";
import { actions } from "react-redux-form";
import {
  AUDIT,
  AUDIT_SUCCESS,
  AUDIT_ERROR,
  REFRESH_USER,
  REFRESH_USER_SUCCESS,
  REFRESH_USER_ERROR,
  GET_OPTIONS,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_ERROR,
  CHECK_EXISTING_USER,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_ERROR,
  SEND_PASSWORD_RESET,
  SEND_PASSWORD_RESET_SUCCESS,
  SEND_PASSWORD_RESET_ERROR,
  VERIFY_PASSWORD_RESET_TOKEN,
  VERIFY_PASSWORD_RESET_TOKEN_SUCCESS,
  VERIFY_PASSWORD_RESET_TOKEN_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SCHEDULES,
  SCHEDULES_SUCCESS,
  SCHEDULES_ERROR,
  PURCHASE_GAME,
  PURCHASE_GAME_SUCCESS,
  PURCHASE_GAME_ERROR,
  SEARCH_GAMES,
  SEARCH_GAMES_SUCCESS,
  SEARCH_GAMES_ERROR,
  SUBMIT_ADMIN_CHANGES,
  SUBMIT_ADMIN_CHANGES_SUCCESS,
  SUBMIT_ADMIN_CHANGES_ERROR,
} from "./constants";
import {
  request,
  toLocalTime,
  formatDateWithTime,
} from "../components/utils/index";

// import { pickBy, keys, result } from "lodash";

function* audit(action) {
  const user = yield select(selectors.user);
  const signup = yield select(selectors.signup);

  const { user_action, description, deal_id, document_id } = action.payload;

  const user_id = user?.id;
  const username = user?.email || signup?.email || "guest";

  let result;
  try {
    const data = {
      user_id,
      username,
      action: user_action,
      description,
      deal_id,
      document_id,
    };
    result = yield request(data, "/audit", "POST", user?.token);
    if (result?.data?.message) {
      yield put({
        type: AUDIT_SUCCESS,
        payload: { message: result?.data?.message },
      });
    } else {
      yield put({
        type: AUDIT_ERROR,
        payload: { error: result?.data?.error },
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: AUDIT_ERROR,
      payload: { error: result?.data?.error },
    });
  }
}

function* refreshUser() {
  let user = yield select(selectors.user);
  let result;
  try {
    result = yield request(
      null,
      `/refreshUser/${user?.id}`,
      "GET",
      user?.token
    );
    if (result?.data?.message) {
      user = { ...user, ...result?.data?.user };
      yield put({
        type: REFRESH_USER_SUCCESS,
        payload: { message: result?.data?.message, user },
      });
    } else {
      yield put({
        type: REFRESH_USER_ERROR,
        payload: { error: result?.data?.error },
      });
      yield put({ type: LOGOUT });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: REFRESH_USER_ERROR,
      payload: { error: result?.data?.error },
    });
    yield put({ type: LOGOUT });
  }
}

function* getOptions() {
  try {
    const result = yield request({}, "/options", "GET");
    if (result?.data?.message) {
      yield put({
        type: GET_OPTIONS_SUCCESS,
        payload: result,
      });
    } else {
      yield put({
        type: GET_OPTIONS_ERROR,
      });
    }
  } catch (error) {
    yield put({
      type: GET_OPTIONS_ERROR,
    });
    console.log(error);
  }
}

function* checkExistingUser(action) {
  const { email } = action.payload;
  let result;
  try {
    result = yield request({}, `/checkexistinguser/${email}`, "GET");
    if (result?.data?.message) {
      yield put(actions.change("signup.existing_user", true));
    } else {
      yield put(actions.change("signup.existing_user", false));
    }
  } catch (error) {
    console.log(error);
  }
}

function* signup(action) {
  const { signup, handleClose } = action.payload;
  let result;
  try {
    let data = {
      ...signup,
    };
    result = yield request(data, "/signup", "POST");
    if (result?.data?.message) {
      yield put({
        type: SIGNUP_SUCCESS,
        payload: {
          ...result?.data,
        },
      });
      yield put({
        type: REFRESH_USER,
      });
      handleClose();
      // Send signup email
      const user = yield select(selectors.user);
      yield request(data, "/mail/signup", "POST", user.token);
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: SIGNUP_ERROR,
        payload: { message: result?.data?.error },
      });
    }
    // yield put(actions.change("alertMessage.error", result?.data?.error));
  } catch (error) {
    console.log(error);
    if (result?.data?.error) {
      yield put({
        type: SIGNUP_ERROR,
        payload: { message: result?.data?.error },
      });
      // yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  }
}

function* login(action) {
  const { login, handleClose } = action.payload;
  let result;
  try {
    const data = {
      ...login,
    };
    result = yield request(data, "/login", "POST");
    if (result?.data?.message) {
      yield put({
        type: LOGIN_SUCCESS,
        payload: {
          ...result?.data,
        },
      });
      handleClose();
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else if (result?.data?.error) {
      yield put({
        type: LOGIN_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: LOGIN_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* sendPasswordReset(action) {
  const { email } = action?.payload;
  let result;
  try {
    const data = {
      email,
    };
    result = yield request(data, "/mail/sendPasswordReset", "POST");
    if (result?.data?.message) {
      yield put({
        type: SEND_PASSWORD_RESET_SUCCESS,
        payload: { message: result?.data?.message },
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: SEND_PASSWORD_RESET_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: SEND_PASSWORD_RESET_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* verifyPasswordReset(action) {
  const { token } = action.payload;
  let result;
  try {
    const data = {
      token,
    };
    result = yield request(data, "/verifyPasswordReset", "POST");
    if (result?.data?.message) {
      yield put({
        type: VERIFY_PASSWORD_RESET_TOKEN_SUCCESS,
        payload: { message: result?.data?.message, email: result.data.email },
      });
      // yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: VERIFY_PASSWORD_RESET_TOKEN_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: VERIFY_PASSWORD_RESET_TOKEN_ERROR,
      payload: { message: result?.data?.error },
    });
  }
}

function* resetPassword(action) {
  const { reset_password, token } = action?.payload;
  const passwordReset = yield select(selectors.passwordReset);
  let result;
  try {
    const data = {
      reset_password,
      email: passwordReset,
    };
    result = yield request(data, "/resetPassword", "POST", token);
    if (result?.data?.message) {
      yield put({
        type: RESET_PASSWORD_SUCCESS,
        payload: { message: result?.data?.message },
      });
      // window.location.href = "/";
      // yield put({
      //   type: AUDIT,
      //   payload: {
      //     user_action: `PASSWORD RESET: ${resetPassword?.email}`,
      //     description: ``,
      //   },
      // });
      // yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: RESET_PASSWORD_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: RESET_PASSWORD_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* logout() {
  try {
    yield request({}, "/logout", "GET");
    window.location.href = "/";
  } catch (error) {
    yield put({
      type: LOGOUT_ERROR,
      payload: "Unable to logout",
    });
  }
}

function* getSchedules() {
  let result;
  try {
    result = yield request(null, `/schedules`, "GET");
    if (result?.data?.message) {
      let nfl_schedules = result?.data?.nfl?.schedules;
      if (nfl_schedules?.length) {
        result.data.nfl.schedules = nfl_schedules?.map((s) => {
          const localTime = toLocalTime(s?.date_time);
          s.date_time = formatDateWithTime(
            localTime?.date,
            true,
            localTime?.timezone
          );
          return s;
        });
      }
      yield put({
        type: SCHEDULES_SUCCESS,
        payload: {
          message: result?.data?.message,
          nfl: result?.data?.nfl,
        },
      });
      // yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: SCHEDULES_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: SCHEDULES_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* purchaseGame(action) {
  let result;
  const { purchase_info } = action.payload;
  const user = yield select(selectors.user);
  try {
    const data = {
      purchase_info,
      first_purchase: !user?.purchased_games?.length,
    };
    result = yield request(data, `/purchaseGame`, "POST", user?.token);
    if (result?.data?.message) {
      yield put({
        type: PURCHASE_GAME_SUCCESS,
        payload: {
          message: result?.data?.message,
        },
      });
      yield put({
        type: REFRESH_USER,
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: PURCHASE_GAME_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: PURCHASE_GAME_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* searchGames(action) {
  let result;
  const { season, week } = action.payload;
  const user = yield select(selectors.user);
  try {
    result = yield request(
      null,
      `/searchGames/${season}/${week}`,
      "GET",
      user?.token
    );
    if (result?.data?.message) {
      let nfl_schedules = result?.data?.nfl?.games;
      if (nfl_schedules?.length) {
        result.data.nfl.games = nfl_schedules?.map((s) => {
          const localTime = toLocalTime(s?.date_time);
          s.date_time = formatDateWithTime(
            localTime?.date,
            true,
            localTime?.timezone
          );
          return s;
        });
      }
      yield put({
        type: SEARCH_GAMES_SUCCESS,
        payload: {
          message: result?.data?.message,
          nfl: result?.data?.nfl,
        },
      });
    } else {
      yield put({
        type: SEARCH_GAMES_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: SEARCH_GAMES_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* submitAdminChanges(action) {
  let result;
  const user = yield select(selectors.user);
  const userActions = yield select(selectors.userActions);
  try {
    const data = {
      ...action.payload,
    };
    result = yield request(data, `/adminOverrides`, "POST", user?.token);
    if (result?.data?.message) {
      const { season, week } = userActions?.admin_actions?.search;
      yield put({
        type: SEARCH_GAMES,
        payload: { season, week },
      });
      yield put({
        type: SUBMIT_ADMIN_CHANGES_SUCCESS,
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: SUBMIT_ADMIN_CHANGES_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: SEARCH_GAMES_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* actionWatcher() {
  yield takeLeading(AUDIT, audit);
  yield takeLeading(REFRESH_USER, refreshUser);
  yield takeLeading(GET_OPTIONS, getOptions);
  yield takeLeading(CHECK_EXISTING_USER, checkExistingUser);
  yield takeLeading(SIGNUP, signup);
  yield takeLeading(LOGIN, login);
  yield takeLeading(LOGOUT, logout);
  yield takeLeading(SEND_PASSWORD_RESET, sendPasswordReset);
  yield takeLeading(VERIFY_PASSWORD_RESET_TOKEN, verifyPasswordReset);
  yield takeLeading(RESET_PASSWORD, resetPassword);
  yield takeLeading(SCHEDULES, getSchedules);
  yield takeLeading(PURCHASE_GAME, purchaseGame);
  yield takeLeading(SEARCH_GAMES, searchGames);
  yield takeLeading(SUBMIT_ADMIN_CHANGES, submitAdminChanges);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
