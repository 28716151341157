import { combineReducers } from "redux";
import {
  createForms, // optional
} from "react-redux-form";
import {
  REFRESH_USER,
  REFRESH_USER_SUCCESS,
  REFRESH_USER_ERROR,
  GET_OPTIONS,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_ERROR,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_ERROR,
  SEND_PASSWORD_RESET,
  SEND_PASSWORD_RESET_SUCCESS,
  SEND_PASSWORD_RESET_ERROR,
  VERIFY_PASSWORD_RESET_TOKEN,
  VERIFY_PASSWORD_RESET_TOKEN_SUCCESS,
  VERIFY_PASSWORD_RESET_TOKEN_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SCHEDULES,
  SCHEDULES_SUCCESS,
  SCHEDULES_ERROR,
  SEARCH_GAMES,
  SEARCH_GAMES_SUCCESS,
  SEARCH_GAMES_ERROR,
} from "./constants";

// const initialState = {};

// const userInitialState = {
//   ...this,
//   first_name: "",
//   last_name: "",
//   phone: "",
//   email: "",
//   password: "",
//   loggedIn: false,
// };

const signupInitialState = {
  // first_name: "test",
  // last_name: "Tester",
  // email: "test@test.com",
  // password: "sbr23983",
  // signup_1: true,
  // signup_2: true,
  // signup_3: true,
  // signup_4: true,
};

export const checkToken = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case REFRESH_USER:
      return {
        isLoading: true,
      };
    case REFRESH_USER_SUCCESS:
      return {
        isLoading: false,
        tokenExpired: false,
      };
    case REFRESH_USER_ERROR:
      return {
        isLoading: false,
        tokenExpired: true,
        error: payload.error,
      };
    default:
      return state;
  }
};

export const alertMessage = (state = {}, { type, payload = "" }) => {
  switch (type) {
    default:
      return state;
  }
};

export const options = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case GET_OPTIONS:
      return {
        isLoading: true,
      };
    case GET_OPTIONS_SUCCESS:
      return {
        isLoading: false,
        ...payload.data,
      };
    case GET_OPTIONS_ERROR:
      return {
        isLoading: false,
        data: null,
      };
    default:
      return state;
  }
};

export const signup = (state = signupInitialState, { type, payload = "" }) => {
  switch (type) {
    case SIGNUP:
      return {
        ...state,
        isLoading: true,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case SIGNUP_ERROR:
      return {
        isLoading: false,
        loggedIn: false,
        error: payload.message,
      };
    default:
      return state;
  }
};

export const user = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case LOGIN:
    case SIGNUP_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        login: true,
        isLoading: false,
        ...payload,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        loggedIn: false,
        error: payload.message,
      };
    case REFRESH_USER_SUCCESS:
      return {
        ...state,
        ...payload.user,
      };
    case LOGOUT:
      return {
        isLoading: false,
      };
    default:
      return state;
  }
};

export const generalAlert = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case SIGNUP_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        error: null,
      };
    case SIGNUP_ERROR:
    case LOGIN_ERROR:
      return {
        error: payload.message,
      };
    default:
      return state;
  }
};

export const passwordReset = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case SEND_PASSWORD_RESET:
      return {
        ...state,
        isLoading: true,
      };
    case VERIFY_PASSWORD_RESET_TOKEN:
    case RESET_PASSWORD:
      return {
        ...state,
        isLoading: true,
      };
    case SEND_PASSWORD_RESET_SUCCESS:
      return {
        isLoading: false,
        passwordResetSent: true,
        message: payload.message,
      };
    case SEND_PASSWORD_RESET_ERROR:
      return {
        isLoading: false,
        passwordResetSent: false,
        error: payload.message,
      };
    case VERIFY_PASSWORD_RESET_TOKEN_SUCCESS:
      return {
        isLoading: false,
        verifyPasswordToken: true,
        message: payload.message,
        email: payload.email,
      };
    case VERIFY_PASSWORD_RESET_TOKEN_ERROR:
      return {
        isLoading: false,
        verifyPasswordToken: false,
        error: payload.message,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        isLoading: false,
        passwordResetSuccess: true,
        message: payload.message,
      };
    case RESET_PASSWORD_ERROR:
      return {
        isLoading: false,
        passwordReset: false,
        message: payload.message,
      };
    default:
      return state;
  }
};

export const userActions = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case SIGNUP:
      return {
        isLoading: true,
      };
    case LOGIN_SUCCESS:
    case SIGNUP_SUCCESS:
      return {
        isLoading: false,
      };
    case LOGIN_ERROR:
    case SIGNUP_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.message,
      };
    case LOGOUT:
      return {
        isLoading: true,
      };
    case LOGOUT_ERROR:
      return {
        isLoading: false,
      };
    default:
      return state;
  }
};

export const schedules = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case SCHEDULES:
    case SEARCH_GAMES:
      return {
        isLoading: true,
      };
    case SCHEDULES_SUCCESS:
    case SEARCH_GAMES_SUCCESS:
      return {
        isLoading: false,
        nfl: payload.nfl,
      };
    case SCHEDULES_ERROR:
    case SEARCH_GAMES_ERROR:
      return {
        isLoading: false,
        error: payload.message,
      };
    default:
      return state;
  }
};

export default combineReducers({
  options,
  user,
  checkToken,
  generalAlert,
  schedules,
  ...createForms({
    alertMessage,
    user,
    userActions,
    passwordReset,
  }),
});
