// MISC
export const ERRORS = "ERRORS";
export const NO_ERRORS = "NO_ERRORS";
export const REFRESH_USER = "REFRESH_USER";
export const REFRESH_USER_SUCCESS = "REFRESH_USER_SUCCESS";
export const REFRESH_USER_ERROR = "REFRESH_USER_ERROR";
export const GET_OPTIONS = "GET_OPTIONS";
export const GET_OPTIONS_SUCCESS = "GET_OPTIONS_SUCCESS";
export const GET_OPTIONS_ERROR = "GET_OPTIONS_ERROR";

// AUDIT
export const AUDIT = "AUDIT";
export const AUDIT_SUCCESS = "AUDIT_SUCCESS";
export const AUDIT_ERROR = "AUDIT_ERROR";

// SIGNUP/LOGINS
export const CHECK_EXISTING_USER = "CHECK_EXISTING_USER";
export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const SEND_PASSWORD_RESET = "SEND_PASSWORD_RESET";
export const SEND_PASSWORD_RESET_SUCCESS = "SEND_PASSWORD_RESET_SUCCESS";
export const SEND_PASSWORD_RESET_ERROR = "SEND_PASSWORD_RESET_ERROR";
export const VERIFY_PASSWORD_RESET_TOKEN = "VERIFY_PASSWORD_RESET_TOKEN";
export const VERIFY_PASSWORD_RESET_TOKEN_SUCCESS =
  "VERIFY_PASSWORD_RESET_TOKEN_SUCCESS";
export const VERIFY_PASSWORD_RESET_TOKEN_ERROR =
  "VERIFY_PASSWORD_RESET_TOKEN_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

// SCHEDULES
export const SCHEDULES = "SCHEDULES";
export const SCHEDULES_SUCCESS = "SCHEDULES_SUCCESS";
export const SCHEDULES_ERROR = "SCHEDULES_ERROR";

// PURCHASE GAME
export const PURCHASE_GAME = "PURCHASE_GAME";
export const PURCHASE_GAME_SUCCESS = "PURCHASE_GAME_SUCCESS";
export const PURCHASE_GAME_ERROR = "PURCHASE_GAME_ERROR";

// ADMIN
export const SEARCH_GAMES = "SEARCH_GAMES";
export const SEARCH_GAMES_SUCCESS = "SEARCH_GAMES_SUCCESS";
export const SEARCH_GAMES_ERROR = "SEARCH_GAMES_ERROR";
export const SUBMIT_ADMIN_CHANGES = "SUBMIT_ADMIN_CHANGES";
export const SUBMIT_ADMIN_CHANGES_SUCCESS = "SUBMIT_ADMIN_CHANGES_SUCCESS";
export const SUBMIT_ADMIN_CHANGES_ERROR = "SUBMIT_ADMIN_CHANGES_ERROR";
