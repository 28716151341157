import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Nav,
  Navbar,
  // NavDropdown,
  Row,
  Col,
  Button,
  Container,
  Modal,
} from "react-bootstrap";
import InputFields from "../InputFields";
import { Form, Control } from "react-redux-form";
// import Container from 'react-bootstrap/Container';
import Logo from "../../global/img/logo.png";
import closeIcon from "../../global/img/close.png";
import Loading from "../Loading";
// import userIcon from "../../global/img/user.svg";
import "./style.css";

function SignInModal(props) {
  const [signupFlow, setSignupFlow] = useState(false);
  const [loginFlow, setLoginFlow] = useState(true);
  const [forgotPasswordFlow, setForgotPasswordFlow] = useState(false);
  const [resetPasswordFlow, setResetPasswordFlow] = useState(false);
  const { dispatch, userActions, onHide, fpToken, passwordReset } = props;

  useEffect(() => {
    if (fpToken) {
      dispatch({
        type: "VERIFY_PASSWORD_RESET_TOKEN",
        payload: {
          token: fpToken,
        },
      });
      setResetPasswordFlow(true);
      setLoginFlow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignup = () => {
    dispatch({
      type: "SIGNUP",
      payload: { signup: userActions.signup, handleClose: () => onHide() },
    });
  };

  const handleLogin = () => {
    dispatch({
      type: "LOGIN",
      payload: { login: userActions.login, handleClose: () => onHide() },
    });
  };

  const handleForgotPassword = () => {
    dispatch({
      type: "SEND_PASSWORD_RESET",
      payload: {
        email: userActions?.forgot_password?.email,
        handleClose: () => onHide(),
      },
    });
  };

  const handleResetPassword = () => {
    dispatch({
      type: "RESET_PASSWORD",
      payload: {
        reset_password: userActions.reset_password,
        token: fpToken,
        handleClose: () => onHide(),
      },
    });
  };

  const checkPasswordMatch = () => {
    if (
      userActions?.reset_password?.password?.length >= 6 &&
      userActions?.reset_password?.confirm_password?.length >= 6 &&
      userActions?.reset_password?.password !==
        userActions?.reset_password?.confirm_password
    ) {
      return "Passwords do not match.";
    }
    return "";
  };

  return (
    <Modal
      {...props}
      id="loginModal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {userActions?.isLoading ? (
          <div className="loginDiv">
            <div className="inputCol">
              <Loading inline={true} />
            </div>
          </div>
        ) : (
          <Row>
            {passwordReset?.passwordResetSuccess ? (
              <Col lg={12}>
                <div className="loginDiv">
                  <h3 className="loginModalHeader tertiaryFontBold">
                    Password Reset Succesfully
                  </h3>
                  <Button href="/" title="Home" className="loginButton">
                    Home
                  </Button>
                </div>
              </Col>
            ) : null}
            {resetPasswordFlow && !passwordReset?.passwordResetSuccess ? (
              <Col lg={12}>
                <div className="loginDiv">
                  <a
                    href="#/"
                    onClick={() => props.onHide()}
                    className="modalCloseBtnLink"
                  >
                    <img
                      src={closeIcon}
                      alt="Close"
                      className="modalCloseBtn"
                    />
                  </a>
                  <h3 className="loginModalHeader tertiaryFontBold">
                    Reset Password
                  </h3>
                  <Form
                    model="userActions.reset_password"
                    onSubmit={() => handleResetPassword()}
                  >
                    <div className="inputCol">
                      <InputFields
                        model=".password"
                        inputType="text"
                        type="password"
                        placeholder="Password"
                      />
                    </div>
                    <div className="inputCol">
                      <InputFields
                        model=".confirm_password"
                        inputType="text"
                        type="password"
                        placeholder="Confirm Password"
                      />
                    </div>
                    <div className="inputCol">
                      {/* eslint-disable-next-line react/jsx-pascal-case */}
                      <Control.button
                        model="userActions.reset_password"
                        className="loginButton"
                        type="submit"
                        disabled={
                          userActions?.reset_password?.password !==
                          userActions?.reset_password?.confirm_password
                        }
                      >
                        Reset Password
                      </Control.button>
                    </div>
                  </Form>
                  <b style={{ color: "red" }}>{checkPasswordMatch()}</b>
                </div>
              </Col>
            ) : null}
            {forgotPasswordFlow ? (
              <Col lg={12}>
                <div className="forgotPwdDiv">
                  <a
                    href="#/"
                    onClick={() => props.onHide()}
                    className="modalCloseBtnLink"
                  >
                    <img
                      src={closeIcon}
                      alt="Close"
                      className="modalCloseBtn"
                    />
                  </a>
                  <h3 className="loginModalHeader tertiaryFontBold">
                    Forgot password?
                  </h3>
                  <Form
                    className=""
                    model="userActions.forgot_password"
                    onSubmit={() => handleForgotPassword()}
                  >
                    <div className="inputCol">
                      <InputFields
                        model=".email"
                        inputType="text"
                        type="email"
                        placeholder="Email"
                      />
                      <a
                        href="#/"
                        className="generalLink loginFlowLink"
                        onClick={() => {
                          setForgotPasswordFlow(false);
                          setLoginFlow(true);
                        }}
                      >
                        Back to Login
                      </a>
                    </div>
                    <div className="inputCol">
                      {/* eslint-disable-next-line react/jsx-pascal-case */}
                      <Control.button
                        model="userActions.forgot_password"
                        className="loginButton"
                        type="submit"
                      >
                        Reset Password
                      </Control.button>
                    </div>
                  </Form>
                </div>
              </Col>
            ) : null}
            {signupFlow ? (
              <Col lg={12}>
                <div className="loginDiv">
                  <a
                    href="#/"
                    onClick={() => props.onHide()}
                    className="modalCloseBtnLink"
                  >
                    <img
                      src={closeIcon}
                      alt="Close"
                      className="modalCloseBtn"
                    />
                  </a>
                  <h3 className="loginModalHeader tertiaryFontBold">
                    Sign Up for Access
                  </h3>
                  <Form
                    model="userActions.signup"
                    onSubmit={() => handleSignup()}
                  >
                    <div className="inputCol">
                      <InputFields
                        model=".first_name"
                        inputType="text"
                        type="text"
                        placeholder="First Name"
                      />
                    </div>
                    <div className="inputCol">
                      <InputFields
                        model=".last_name"
                        inputType="text"
                        type="text"
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="inputCol">
                      <InputFields
                        model=".email"
                        inputType="text"
                        type="email"
                        placeholder="Email"
                      />
                    </div>
                    <div className="inputCol">
                      <InputFields
                        model=".password"
                        inputType="text"
                        type="password"
                        placeholder="Password"
                      />
                    </div>
                    <div className="inputCol">
                      <InputFields
                        model=".zipcode"
                        inputType="number"
                        type="number"
                        placeholder="Zipcode"
                        minLength="5"
                        maxLength="5"
                      />
                      <a
                        href="#/"
                        className="generalLink loginFlowLink"
                        onClick={() => {
                          setSignupFlow(false);
                          setLoginFlow(true);
                        }}
                      >
                        Already have an account?
                      </a>
                    </div>
                    <div className="inputCol">
                      {/* eslint-disable-next-line react/jsx-pascal-case */}
                      <Control.button
                        model="userActions.signup"
                        className="loginButton"
                        type="submit"
                      >
                        Sign Up
                      </Control.button>
                    </div>
                  </Form>
                </div>
              </Col>
            ) : null}
            {loginFlow ? (
              <>
                <Col lg={12}>
                  <div className="loginDiv">
                    <a
                      href="#/"
                      onClick={() => props.onHide()}
                      className="modalCloseBtnLink"
                    >
                      <img
                        src={closeIcon}
                        alt="Close"
                        className="modalCloseBtn"
                      />
                    </a>
                    <h3 className="loginModalHeader tertiaryFontBold">
                      Login for Access
                    </h3>
                    {/* <button className="thirdPartySignInBtn">
                      Sign in with Google
                    </button>
                    <button className="thirdPartySignInBtn">
                      Sign in with Apple
                    </button>
                    <div className="hrDiv">
                      <span>Or</span>
                    </div> */}
                    <Form
                      className="loginForm"
                      model="userActions.login"
                      onSubmit={() => handleLogin()}
                    >
                      <div className="inputCol">
                        <InputFields
                          model=".email"
                          inputType="text"
                          type="email"
                          placeholder="Email"
                        />
                        <a
                          href="#/"
                          className="generalLink loginFlowLink"
                          onClick={() => {
                            setSignupFlow(true);
                            setLoginFlow(false);
                          }}
                        >
                          Don't have an account?
                        </a>
                      </div>
                      <div className="inputCol">
                        <InputFields
                          model=".password"
                          inputType="text"
                          type="password"
                          placeholder="Password"
                        />
                        <a
                          href="#/"
                          className="generalLink loginFlowLink"
                          onClick={() => {
                            setForgotPasswordFlow(true);
                            setLoginFlow(false);
                          }}
                        >
                          Forgot Password?
                        </a>
                      </div>
                      <div className="inputCol">
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Control.button
                          model="userActions.login"
                          className="loginButton"
                          type="submit"
                        >
                          Sign In
                        </Control.button>
                      </div>
                    </Form>
                  </div>
                </Col>
              </>
            ) : null}
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
}

function NavbarHeader(props) {
  const {
    user,
    userActions,
    dispatch,
    loginModalShow,
    setLoginModalShow,
    passwordReset,
  } = props;
  const [fpToken, setFPToken] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setFPToken(queryParams.get("fp_token"));
    if (fpToken) {
      setLoginModalShow(true);
    }
  }, [fpToken, setLoginModalShow]);

  return (
    <>
      <Navbar
        id="menu"
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary secondaryFontRegular"
      >
        <Container>
          {loginModalShow ? (
            <SignInModal
              show={loginModalShow}
              onHide={() =>
                !passwordReset?.passwordResetSuccess && setLoginModalShow(false)
              }
              userActions={userActions}
              dispatch={dispatch}
              fpToken={fpToken}
              passwordReset={passwordReset}
            />
          ) : null}
          {/* <Navbar.Brand href="#home">Inside Injuries</Navbar.Brand> */}
          {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
          <Navbar.Toggle
            className="toggleMenu"
            aria-controls="responsive-navbar-nav"
          >
            <div className="toggleMenuDiv">
              <span className="toggleMenuLine" />
              <span className="toggleMenuLine" />
              <span className="toggleMenuLine" />
            </div>
          </Navbar.Toggle>
          <a href="/" className="logoLinkMobile">
            <img src={Logo} alt="Logo" className="logo" />
          </a>
          <Navbar.Collapse id="responsive-navbar-nav">
            {/* COMMENT WHEN SHOWING NAVLINKS AGAIN */}
            {/* <Nav className="me-auto"> */}
            {/* UNCOMMENT WHEN SHOWING NAVLINKS AGAIN */}
            <Nav>
              {/* REMOVE WHEN SHOWING NAVLINKS AGAIN */}
              {/* <Nav.Link href="/" className="hideMobile">
                <img src={Logo} alt="Logo" className="logo" />
              </Nav.Link> */}
              <Nav.Link
                href="https://insideinjuries.com/about-us/"
                target="_blank"
                rel="noreferrer"
              >
                Why trust us?
              </Nav.Link>
              <Nav.Link
                href="https://insideinjuries.com/white-paper/"
                target="_blank"
                rel="noreferrer"
              >
                Review our Success
              </Nav.Link>
              {/* <Nav.Link href="?nba">NBA</Nav.Link> */}
              {/* <Nav.Link href="?nfl">NFL</Nav.Link> */}
              {/* <Nav.Link href="?laliga">LA LIGA</Nav.Link> */}
              {/* <Nav.Link href="?premierleague">PREMIER LEAGUE</Nav.Link> */}
              {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
            {/* UNCOMMENT WHEN SHOWING NAVLINKS AGAIN */}
            <a href="/" className="logoLink">
              <img src={Logo} alt="Logo" className="logo" />
            </a>
            <Nav>
              <Nav.Link href="mailto: info@insideinjuries.com">HELP</Nav.Link>
              {user?.admin && (
                <Nav.Link eventKey={2} href="/admin">
                  ADMIN
                </Nav.Link>
              )}
              <Nav.Link
                eventKey={3}
                href="#/"
                onClick={() =>
                  !user?.login
                    ? setLoginModalShow(true)
                    : dispatch({ type: "LOGOUT" })
                }
              >
                {!user?.login ? "LOGIN" : "LOGOUT"}
                {/* {!user?.login ? "LOGIN" : "MY ACCOUNT"} */}
                {/* <div className="profileIconDiv">
                  <img src={userIcon} alt="Profile" className="profileIcon" />
                </div> */}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  passwordReset: state.passwordReset,
});

export default connect(mapStateToProps)(NavbarHeader);
