import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Home from "./components/Home";
import Admin from "./components/Admin";
// import InvestorResources from "./components/InvestorResources";
// import Login from "./components/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./global/css/globalStyle.css";
import Loading from "./components/Loading";
import AlertMessage from "./components/AlertMessage";
import NavbarHeader from "./components/Navbar";

function App(props) {
  useEffect(() => {
    const { user, dispatch } = props;
    if (user?.login) {
      dispatch({ type: "REFRESH_USER" });
    }
    // dispatch({ type: "GET_OPTIONS" });
    dispatch({ type: "SCHEDULES" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loginModalShow, setLoginModalShow] = useState(false);

  const { userActions, schedules } = props;
  if (userActions?.isLoading || schedules?.isLoading) {
    return <Loading />;
  }

  return (
    <div className="App">
      <div className="mainFontBold">
        <AlertMessage />
      </div>
      <NavbarHeader
        loginModalShow={loginModalShow}
        setLoginModalShow={setLoginModalShow}
      />
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={<Home setLoginModalShow={setLoginModalShow} />}
          />
          <Route
            exact
            path="/admin"
            element={<Admin setLoginModalShow={setLoginModalShow} />}
          />
          <Route
            exact
            path="/resetpassword"
            element={<Home setLoginModalShow={setLoginModalShow} />}
          />
          <Route exact path="/logout" element={<Home />} />
          {/* <Route
            exact
            path="/login"
            element={<Login handleClose={() => handleShow(false)} />}
          /> */}
          {/* <Route
            exact
            path="/resetpassword"
            element={<Login handleClose={() => handleShow(false)} />}
          /> */}
        </Routes>
      </Router>
    </div>
  );
}

// export default App;

const mapStateToProps = (state) => ({
  checkToken: state.checkToken,
  ipAddr: state.ipAddr,
  user: state.user,
  userActions: state.userActions,
  schedules: state.schedules,
});

export default connect(mapStateToProps)(App);
