import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { actions } from "react-redux-form";
import Close from "../../global/img/closeWhite.png";
import "./style.css";

class AlertMessage extends Component {
  render() {
    const { alertMessage, dispatch } = this.props;
    const alertText = alertMessage?.message || alertMessage?.error;
    const error = alertMessage?.error;
    if (alertMessage?.message || alertMessage?.error) {
      setTimeout(function () {
        dispatch(actions.change("alertMessage", {}));
        dispatch(actions.change("userActions.message", null));
        dispatch(actions.change("userActions.error", null));
      }, 5000);
    }
    if (alertText) {
      return (
        <div
          className={`alertDiv ${!error ? "alertDivSuccess" : "alertDivError"}`}
          onClose={() => {
            dispatch(actions.change("alertMessage", {}));
            dispatch(actions.change("userActions.message", null));
            dispatch(actions.change("userActions.error", null));
          }}
        >
          <p className="alertText sourceBold">{alertText}</p>
          <Button className="alertCloseBtn">
            <img
              src={Close}
              alt="Close"
              className="alertCloseImg"
              onClick={() => {
                dispatch(actions.change("alertMessage", {}));
                dispatch(actions.change("userActions.message", null));
                dispatch(actions.change("userActions.error", null));
              }}
            />
          </Button>
        </div>
      );
    } else {
      return "";
    }
  }
}

const mapStateToProps = (state) => ({
  checkToken: state.checkToken,
  alertMessage: state.alertMessage,
});

export default connect(mapStateToProps)(AlertMessage);
