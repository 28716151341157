/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Modal, Container } from "react-bootstrap";
// import InputFields from "../InputFields";
import { actions } from "react-redux-form";
// import Footer from "../Footer";
// import Loading from "../Loading";
// import NavIcon from "./navIcons";
import CheckmarkIcon from "../../global/img/circle-check.svg";
import XIcon from "../../global/img/circle-xmark.svg";
import closeIcon from "../../global/img/close.png";
import { request } from "../utils/index";
import "./style.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkoutForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function PaymentModal(props) {
  const appearance = {
    theme: "night",
    labels: "floating",
    variables: {
      colorPrimary: "#39aabe",
      colorBackground: "#ffffff",
      colorText: "#39aabe",
      colorDanger: "#df1b41",
      // fontFamily: "Bebas Neue, sans-serif",
      fontFamily: "Staatliches",
      spacingUnit: "2px",
      borderRadius: "4px",
      // See all possible variables below
    },
  };
  const options = {
    clientSecret: props.clientSecret,
    appearance,
  };

  const purchaseInfo = {
    user_id: props?.userId,
    game_id: props?.gameForPurchase?.id,
    price: props.gameForPurchase?.price,
  };

  return (
    <Modal
      {...props}
      id="paymentModal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <span>
          Purchase Price: <b>${props.gameForPurchase?.price}</b>
        </span>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg={12}>
            <div className="paymentDiv">
              <h6 className="paymentModalHeader">Payment Method</h6>
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm
                  purchaseInfo={purchaseInfo}
                  dispatch={props?.dispatch}
                  onHide={props?.onHide}
                  email={props?.email}
                />
              </Elements>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

function MatchupModal(props) {
  const { matchupInfo } = props;
  const {
    away_team_city,
    away_team_name,
    home_team_city,
    home_team_name,
    over_under_prediction_outcome,
    away_team_logo,
    home_team_logo,
    over_under,
    point_spread,
    disable_over_under_bet,
    disable_spread_bet,
    date_time,
    // away_team_score,
    // home_team_score,
    // status,
    // over_under_prediction_correct,
    // spread_prediction_correct,
  } = matchupInfo;
  let favoredTeamBySpread;

  let pointSpreadLogo;
  if (point_spread >= 0) {
    pointSpreadLogo = (
      <img
        src={away_team_logo}
        alt="Team Logo"
        className="matchupPointSpreadLogo"
      />
    );
  } else {
    pointSpreadLogo = (
      <img
        src={home_team_logo}
        alt="Team Logo"
        className="matchupPointSpreadLogo"
      />
    );
  }

  if (
    matchupInfo?.favored_team_by_predicted_spread
      ?.split(":")[0]
      ?.toUpperCase() === "AWAY"
  ) {
    favoredTeamBySpread = `${away_team_city} ${away_team_name}`;
  } else {
    favoredTeamBySpread = `${home_team_city} ${home_team_name}`;
  }
  return (
    <Modal
      {...props}
      id="matchupModal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg={12}>
            <a
              href="#/"
              onClick={() => props.onHide()}
              className="modalCloseBtnLink"
            >
              <img src={closeIcon} alt="Close" className="modalCloseBtn" />
            </a>
            <div className="matchupLogoDiv mainFontBold">
              <div>
                <img
                  src={away_team_logo}
                  // src={matchupInfo?.away_team_logo}
                  alt="Away Team Logo"
                  className="matchupLogo"
                />
                <h3>
                  {away_team_city}
                  <br />
                  {away_team_name}
                </h3>
              </div>
              <b className="mainFontBold matchupVS">VS</b>
              <div>
                <img
                  src={home_team_logo}
                  // src={matchupInfo?.home_team_logo}
                  alt="Home Team Logo"
                  className="matchupLogo"
                />
                <h3>
                  {home_team_city}
                  <br />
                  {home_team_name}
                </h3>
              </div>
            </div>
            <p className="matchupLogoDateTime tertiaryFontBold">
              {date_time?.date}
              <br />
              {date_time?.time}
            </p>
            <div className="matchupExpertPickDiv">
              {/* {status?.toUpperCase() === "FINAL" && (
                <>
                  <p className="matchupWinner mainFontBold">
                    {away_team_score > home_team_score ? (
                      <span>
                        {away_team_city} {away_team_name}
                      </span>
                    ) : (
                      <span>
                        {home_team_city} {home_team_name}
                      </span>
                    )}{" "}
                    Win
                  </p>
                  <h3 className="mainFontBold">FINAL SCORE</h3>
                  <>
                    {" "}
                    <p className="matchupFinalScore mainFontBold">
                      {away_team_score} - {home_team_score}
                    </p>
                    <hr className="matchupExpertPickHR" />
                    <h3 className="matchPredictionResultsHeader mainFontBold">
                      PREDICTION RESULTS
                    </h3>
                    <div className="matchPredictionResultsDiv mainFontBold">
                      <p>
                        O/U
                        <br />
                        {over_under_prediction_correct ? (
                          <img
                            src={CheckmarkIcon}
                            alt="Checkmark"
                            className="checkmarkIcon"
                          />
                        ) : (
                          <img src={XIcon} alt="X" className="xIcon" />
                        )}
                      </p>
                      <p>
                        SPREAD
                        <br />
                        {spread_prediction_correct ? (
                          <img
                            src={CheckmarkIcon}
                            alt="Checkmark"
                            className="checkmarkIcon"
                          />
                        ) : (
                          <img src={XIcon} alt="X" className="xIcon" />
                        )}
                      </p>
                    </div>
                  </>
                </>
              )}
              <hr className="matchupExpertPickHR" /> */}
              <h3 className="mainFontBold">EXPERT PICK</h3>
              <hr className="matchupExpertPickHR" />
              <Row>
                <Col lg={6}>
                  <p className="matchupExpertPickHeader mainFontRegular">
                    OVER/UNDER:
                    <br />
                    <b className="matchupExpertPickValue mainFontBold">
                      {over_under}
                    </b>
                  </p>
                  <p className="matchupExpertPickHeader mainFontRegular">
                    POINT SPREAD:
                    <br />
                    <b className="matchupExpertPickValue mainFontBold">
                      {pointSpreadLogo}{" "}
                      {point_spread < 0 ? point_spread : `-${point_spread}`}
                    </b>
                  </p>
                </Col>
                <hr className="matchupExpertPickHR matchupExpertPickHRMobile" />
                <Col lg={6}>
                  <p className="matchupExpertPickHeader mainFontRegular">
                    OVER/UNDER PREDICTION:
                    <br />
                    <b className="matchupExpertPickValue mainFontBold">
                      {!disable_over_under_bet
                        ? over_under_prediction_outcome
                        : "Unpredictable"}
                    </b>
                  </p>
                  <p className="matchupExpertPickHeader mainFontRegular">
                    OUR FAVORED TEAM BY SPREAD:
                    <br />
                    <b className="matchupExpertPickValue mainFontBold">
                      {!disable_spread_bet
                        ? favoredTeamBySpread
                        : "Unpredictable"}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* <p className="matchupExpertPickHeader secondaryFontRegular">
                OVER/UNDER:
                <br />
                <b className="matchupExpertPickValue mainFontBold">
                  55 {over_under_prediction_outcome}
                </b>
              </p>
              <p className="matchupExpertPickHeader secondaryFontRegular">
                FAVORED TEAM BY SPREAD:
                <br />
                <b className="matchupExpertPickValue mainFontBold">
                  -6.5 {favoredTeamBySpread}
                </b>
              </p> */}
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

function Home(props) {
  const { dispatch, schedules, user, setLoginModalShow } = props;
  // const [activeBetSport, handleActiveBetSport] = useState("NFL");
  // const [activeFantasySport, handleActiveFantasySport] = useState("NFL");
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [gameForPurchase, setGameForPurchase] = useState(null);
  const [teamSearch, setTeamSearch] = useState(null);
  const [matchupModalShow, setMatchupModalShow] = useState(false);

  let nfl_schedules = schedules?.nfl?.schedules;
  const nfl_bets_tracker = schedules?.nfl?.bets_tracker;
  const nfl_success_rate = schedules?.nfl?.success_rate;
  const nflSuccessRateCSSProperty = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--success-rate");
  if (nfl_success_rate && !nflSuccessRateCSSProperty) {
    window.CSS.registerProperty({
      name: "--num",
      syntax: "<integer>",
      initialValue: nfl_success_rate,
      inherits: false,
    });

    window.CSS.registerProperty({
      name: "--success-rate",
      syntax: "<integer>",
      initialValue: nfl_success_rate,
      inherits: false,
    });
  }

  const searchFilter = (v) => {
    const searchValue = v?.target?.value?.toUpperCase();
    const filteredSearch = searchValue
      ? schedules?.nfl?.schedules?.filter(
          (n) =>
            n?.away_team_city?.toUpperCase()?.startsWith(searchValue) ||
            n?.away_team_name?.toUpperCase()?.startsWith(searchValue) ||
            n?.home_team_city?.toUpperCase()?.startsWith(searchValue) ||
            n?.home_team_name?.toUpperCase()?.startsWith(searchValue)
        )
      : schedules?.nfl?.schedules;
    setTeamSearch(filteredSearch);
    return;
  };

  if (teamSearch) {
    nfl_schedules = teamSearch;
  }

  const submitStripePaymentIntent = async (item) => {
    // Create PaymentIntent as soon as the page loads
    let data = {
      item,
      email: user?.email,
      customer: user?.stripe_customer_id,
    };
    const result = await request(
      data,
      "/createPaymentIntent",
      "POST",
      user?.token
    );
    if (result?.data?.clientSecret) {
      setClientSecret(result?.data?.clientSecret);
    } else {
      dispatch(actions.change("alertMessage.error", result?.data?.message));
    }
  };

  // const SportsIcon = (sport) => {
  //   ({ sport } = sport);
  //   return NavIcon(
  //     sport,
  //     activeBetSport === sport ? "#39aabe" : "transparent",
  //     activeBetSport === sport ? "#ffffff" : "#39aabe"
  //   );
  // };

  const purchasedGames = user?.purchased_games?.map((g) => g?.game_id);

  return (
    <Container>
      {clientSecret ? (
        <PaymentModal
          show={paymentModalShow}
          onHide={() => setPaymentModalShow(false)}
          clientSecret={clientSecret}
          gameForPurchase={gameForPurchase}
          userId={user?.id}
          dispatch={dispatch}
          email={user?.email}
        />
      ) : null}
      <MatchupModal
        show={matchupModalShow}
        onHide={() => setMatchupModalShow(false)}
        matchupInfo={matchupModalShow}
      />
      <div className="containerDiv">
        <Row>
          <Col lg={3}>
            <div className="homeAdSection">
              <div className="homeAdDiv mainFontBold">
                <h2 className="homeAdHeader">CURRENT WIN RATE:</h2>
                {/* <p>2022 NFL Season</p> */}
                {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                <h1 className="betsWinPercentage"></h1>
                <h2>For the Under</h2>
                {/* <p className="homeAdText mainFontBold">
                <b>
                  GO BEYOND THE HEADLINES WITH OUR PREMIUM SUBSCRIPTION AND GET
                  THE INSIDE SCOOP ON ALL THINGS SPORTS
                </b>
              </p>
              <hr className="adHR" />
              <ul className="homeAdList mainFontMedium">
                <li>CURATED STORIES TO YOUR INTERESTS</li>
                <li>IN DEPTH ANALYSIS THAT GOES ABOVE AND REYTINTE</li>
                <li>THE MOST RECENT UPDATES</li>
              </ul>
              <p className="mainFontRegular" style={{ margin: 0 }}>
                TRY{" "}
                <u>
                  <b>TODAY</b>
                </u>
              </p> */}
              </div>
              <div className="homeTwitterDiv">
                <a
                  className="twitter-timeline mainFontBold twitterLink"
                  data-height="500"
                  data-theme="light"
                  href="https://twitter.com/InsideInjuries?ref_src=twsrc%5Etfw"
                >
                  Inside Injuries News Feed
                </a>
              </div>
            </div>
          </Col>
          <Col lg={6} className="homeCardSection homeCardSection1">
            <h1 className="secondaryFontRegular homeCardHeader">GAME BETS</h1>
            {/* <div className="sportsNavDiv">
              <Row>
                <Col lg={3}>
                  <button
                    className={
                      activeBetSport === "NBA"
                        ? "sportsNavButtonActive"
                        : "sportsNavButton"
                    }
                    alt="NBA"
                    onClick={() => handleActiveBetSport("NBA")}
                  >
                    <div className="sportsNavIconDiv">
                      <SportsIcon sport="NBA" />
                    </div>
                    NBA
                  </button>
                </Col>
                <Col lg={3}>
                  <button
                    className={
                      activeBetSport === "NFL"
                        ? "sportsNavButtonActive"
                        : "sportsNavButton"
                    }
                    alt="NFL"
                    onClick={() => handleActiveBetSport("NFL")}
                  >
                    <div className="sportsNavIconDiv">
                      <SportsIcon sport="NFL" />
                    </div>
                    NFL
                  </button>
                </Col>
                <Col lg={3}>
                  <button
                    className={
                      activeBetSport === "EPL"
                        ? "sportsNavButtonActive"
                        : "sportsNavButton"
                    }
                    alt="EPL"
                    onClick={() => handleActiveBetSport("EPL")}
                  >
                    <div className="sportsNavIconDiv">
                      <SportsIcon sport="EPL" />
                    </div>
                    EPL
                  </button>
                </Col>
                <Col lg={3}>
                  <button
                    className={
                      activeBetSport === "LALIGA"
                        ? "sportsNavButtonActive"
                        : "sportsNavButton"
                    }
                    alt="LA LIGA"
                    onClick={() => handleActiveBetSport("LALIGA")}
                  >
                    <div className="sportsNavIconDiv">
                      <SportsIcon sport="LALIGA" />
                    </div>
                    LA LIGA
                  </button>
                </Col>
              </Row>
            </div> */}
            <form className="searchInput">
              <input
                className="searchInput mainFontRegular"
                type="search"
                placeholder="Search by Team City or Name..."
                onChange={(v) => searchFilter(v)}
              />
            </form>
            <Row className="cardRow">
              {nfl_schedules
                ? nfl_schedules?.map((s) => (
                    <Col key={s?.id} lg={4} md={6} sm={6} className="cardCol">
                      <div className="cardDiv">
                        <div className="cardTeamLogosDiv">
                          <img
                            src={s?.away_team_logo}
                            alt={`${s?.away_team_city} ${s?.away_team_name}`}
                            className="cardTeamLogo"
                          />
                          <b className="mainFontBold">VS</b>
                          <img
                            src={s?.home_team_logo}
                            alt={`${s?.home_team_city} ${s?.home_team_name}`}
                            className="cardTeamLogo"
                          />
                        </div>
                        <p className="tertiaryFontRegular">
                          {s?.date_time?.date}
                          <br />
                          {s?.date_time?.time}
                        </p>
                        {s?.status?.toUpperCase() === "FINAL" ? (
                          <div className="mainFontBold cardFinalBanner">
                            <p>Final</p>
                          </div>
                        ) : (
                          <p className="mainFontBold">
                            <b>UNLOCK EXPERT PICK:</b>
                          </p>
                        )}

                        {!purchasedGames?.includes(s?.id) &&
                        !user?.promo_active &&
                        !user?.admin ? (
                          // s?.status?.toUpperCase() !== "FINAL" ? (
                          <>
                            <button
                              className="cardButton mainFontRegular"
                              title="Card Button"
                              onClick={() => {
                                if (!user?.id) {
                                  setLoginModalShow(true);
                                } else {
                                  setPaymentModalShow(true);
                                  submitStripePaymentIntent({
                                    schedule_id: s?.id,
                                    description: `${s?.away_team_city} ${s?.away_team_name} VS ${s?.home_team_city} ${s?.home_team_name}`,
                                    price: s?.price
                                      ?.toString()
                                      ?.replace(".", ""),
                                  });
                                  setGameForPurchase(s);
                                }
                              }}
                            >
                              ${s?.price}
                            </button>
                            {/* <button
                          className="cardButton mainFontRegular"
                          title="Card Button"
                          onClick={() => {
                            setPaymentModalShow(true);
                            submitStripePaymentIntent({
                              schedule_id: s?.id,
                              description: `${s?.away_team_city} ${s?.away_team_name} VS ${s?.home_team_city} ${s?.home_team_name}`,
                              price: s?.spread_price
                                ?.toString()
                                ?.replace(".", ""),
                            });
                            setGameForPurchase(s);
                          }}
                        >
                          Spread - ${s?.spread_price}
                        </button> */}
                          </>
                        ) : (
                          <button
                            className="cardButton mainFontRegular"
                            title="Card Button"
                            onClick={() => {
                              setMatchupModalShow(s);
                            }}
                          >
                            View Matchup
                          </button>
                        )}
                      </div>
                    </Col>
                  ))
                : null}
            </Row>
          </Col>
          {/* <Col lg={4} className="homeCardSection homeCardSection2">
            <h1 className="secondaryFontRegular homeCardHeader">
              DAILY FANTASY
            </h1>
          </Col> */}
          <Col lg={3}>
            <div className="betsSection">
              <div className="betsDiv mainFontBold">
                <p className="betsHeader">
                  Inside Injuries Doctors Continue Positive Earnings Into 2023
                </p>
                <hr className="betsHr" />
                <p className="storyText tertiaryFontRegular">
                  After winning tens of thousands of dollars by beating the odds
                  78% of the time during the 2022 NFL season, the Inside
                  Injuries doctors continue their success into 2023.
                  <br />
                  <br />
                  Week 1 Bets: $32,000
                  <br />
                  Week 1 Winnings: $1,250
                </p>
              </div>
            </div>
          </Col>
          {/* <Col lg={3}>
            <div className="betsSection">
              <div className="betsDiv mainFontBold">
                <p className="betsHeader">TRACK OUR BETS</p>
                <hr className="betsHr" />
                {nfl_bets_tracker
                  ? nfl_bets_tracker?.map((b, i) => (
                      <div key={i}>
                        {i >= 1 ? <hr className="betsGameHr" /> : null}
                        <div className="betsGameDiv">
                          <div className="betsLogoDiv">
                            <img
                              src={b?.away_team_logo}
                              alt="NFL Team"
                              className="betsLogo"
                            />
                            <b className="mainFontBold">VS</b>
                            <img
                              src={b?.home_team_logo}
                              alt="NFL Team"
                              className="betsLogo"
                            />
                          </div>
                          <p>
                            O/U
                            <br />
                            {b?.over_under_prediction_correct ? (
                              <img
                                src={CheckmarkIcon}
                                alt="Checkmark"
                                className="checkmarkIcon"
                              />
                            ) : (
                              <img src={XIcon} alt="X" className="xIcon" />
                            )}
                          </p>
                          <p>
                            SPREAD
                            <br />
                            {b?.spread_prediction_correct ? (
                              <img
                                src={CheckmarkIcon}
                                alt="Checkmark"
                                className="checkmarkIcon"
                              />
                            ) : (
                              <img src={XIcon} alt="X" className="xIcon" />
                            )}
                          </p>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </Col> */}
        </Row>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  schedules: state.schedules,
});

export default connect(mapStateToProps)(Home);
